import constants from './constants';
import {createCryptoInterceptor} from './crypto-interceptor';
import {base64ToBuffer, bufferToBase64} from "./webauthn";
import {red} from "@mui/material/colors";

const authApi = {
    login: async (email, password) => {
        try {
            const response = await fetch(`${constants.AUTH_API_URL}/login-user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const rawData = await response.text();
            let encryptedData;
            try {
                encryptedData = JSON.parse(rawData);
            }
            catch (e) {
                encryptedData = rawData;
            }

            const decryptedData = await createCryptoInterceptor(() => encryptedData)();

            if (decryptedData?.token) {
                localStorage.setItem('user', JSON.stringify(decryptedData));
                return decryptedData;
            } else {
                throw new Error('Invalid server response');
            }
        }
        catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    },

    logout: () => {
        localStorage.removeItem('user');
    },

    register: async (email, password, firstName, username) => {
        try {
            const response = await fetch(`${constants.AUTH_API_URL}/register-user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                                         firstName,
                                         username,
                                         email,
                                         password
                                     })
            });

            const rawData = await response.text();
            let encryptedData;
            try {
                encryptedData = JSON.parse(rawData);
            }
            catch (e) {
                encryptedData = rawData;
            }

            const decryptedData = await createCryptoInterceptor(() => encryptedData)();

            if (!response.ok) {
                return {
                    success: false,
                    message: decryptedData?.message || 'Registration failed'
                };
            }

            return {
                success: true,
                data: decryptedData
            };
        }
        catch (error) {
            return {
                success: false,
                message: error.message || 'Registration failed'
            };
        }
    },

    getCurrentUser: () => {
        try {
            const userStr = localStorage.getItem('user');
            if (!userStr) return null;

            return JSON.parse(userStr);
        }
        catch (error) {
            console.error('Get current user error:', error);
            localStorage.removeItem('user');
            return null;
        }
    },

    authHeader: () => {
        const user = authApi.getCurrentUser();
        if (user?.token) {
            return { Authorization: `Bearer ${user.token}` };
        }
        return {};
    },

    // New Passkey Authentication Methods
    checkAccount: async (email) => {
        try {
            const response = await fetch(`${constants.AUTH_API_URL}/check-account`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({ email })
            });

            const rawData = await response.text();
            let encryptedData;
            try {
                encryptedData = JSON.parse(rawData);
            }
            catch (e) {
                encryptedData = rawData;
            }

            return await createCryptoInterceptor(() => encryptedData)();
        }
        catch (error) {
            console.error('Account check failed:', error);
            throw error;
        }
    },

    getPasskeyAuthOptions: async (email) => {
        try {
            const response = await fetch(`${constants.AUTH_API_URL}/passkey-auth-options`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({ email })
            });

            const rawData = await response.text();
            let encryptedData;
            try {
                encryptedData = JSON.parse(rawData);
            }
            catch (e) {
                encryptedData = rawData;
            }

            const options = await createCryptoInterceptor(() => encryptedData)();

            // Explicitly set the challenge as a base64 string
            const challengeBase64 = options.publicKey.challenge;

            // Modify the options to include the challenge correctly
            const modifiedOptions = {
                ...options,
                publicKey: {
                    ...options.publicKey,
                    challenge: challengeBase64  // Keep it as a base64 string
                }
            };

            console.log('Original options:', options);
            console.log('Modified options:', modifiedOptions);

            return modifiedOptions;
        }
        catch (error) {
            console.error('Failed to get auth options:', error);
            throw error;
        }
    },

    verifyPasskey: async (credential, options, userId, email) => {
        console.log(options.publicKey.challenge);
        try {
            const response = await fetch(`${constants.AUTH_API_URL}/verify-passkey`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                                         response: {
                                             authenticatorData: Array.from(new Uint8Array(credential.response.authenticatorData)),
                                             clientDataJSON: Array.from(new Uint8Array(credential.response.clientDataJSON)),
                                             signature: Array.from(new Uint8Array(credential.response.signature)),
                                         },
                                         type: credential.type,
                                         challenge: bufferToBase64(options.publicKey.challenge),
                                         id: credential.id,
                                         email: email,
                                         auth_user_id: userId,
                                         rawId: bufferToBase64(credential.rawId),
                                         public_key: options.publicKey,
                                     })
            });

            const rawData = await response.text();
            let encryptedData;
            try {
                encryptedData = JSON.parse(rawData);
            }
            catch (e) {
                encryptedData = rawData;
            }

            const result = await createCryptoInterceptor(() => encryptedData)();
            if (result?.user?.token) {
                localStorage.setItem('user', JSON.stringify(result.user));
            }
            return result;
        }
        catch (error) {
            console.error('Passkey verification failed:', error);
            throw error;
        }
    },

    createAccount: async (email) => {
        try {
            const response = await fetch(`${constants.AUTH_API_URL}/create-account`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Origin': window.location.origin
                },
                credentials: 'include',
                body: JSON.stringify({ email })
            });

            const rawData = await response.text();
            let encryptedData;
            try {
                encryptedData = JSON.parse(rawData);
            }
            catch (e) {
                encryptedData = rawData;
            }

            const options = await createCryptoInterceptor(() => encryptedData)();

            if(options.success){
                // Prepare the options for WebAuthn with explicit authenticator selection
                const publicKey = {
                    ...options.publicKey,
                    challenge: base64ToBuffer(options.publicKey.challenge),
                    user: {
                        ...options.publicKey.user,
                        id: base64ToBuffer(options.publicKey.user.id)
                    },
                    authenticatorSelection: {
                        authenticatorAttachment: "platform",
                        requireResidentKey: true,
                        residentKey: "required",
                        userVerification: "preferred"
                    },
                    rp: {
                        ...options.publicKey.rp,
                        id: window.location.hostname
                    }
                };

                return { publicKey };
            }

            return options;
        }
        catch (error) {
            console.error('Failed to create account:', error);
            throw error;
        }
    },

    registerPasskey: async (email, credential, challenge) => {
        try {
            console.log(credential);
            // Prepare the credential data
            const credentialData = {
                email,
                challenge: bufferToBase64(challenge),
                id: credential.id,
                rawId: bufferToBase64(credential.rawId),
                type: credential.type,
                response: {
                    attestationObject: bufferToBase64(credential.response.attestationObject),
                    clientDataJSON: bufferToBase64(credential.response.clientDataJSON),
                }
            };

            // Log the credential data being sent
            console.log('Sending credential data:', credentialData);

            const response = await fetch(`${constants.AUTH_API_URL}/register-passkey`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Origin': window.location.origin
                },
                credentials: 'include',
                body: JSON.stringify(credentialData)
            });

            const rawData = await response.text();
            let encryptedData;
            try {
                encryptedData = JSON.parse(rawData);
            }
            catch (e) {
                encryptedData = rawData;
            }

            const result = await createCryptoInterceptor(() => encryptedData)();
            if (result?.token) {
                localStorage.setItem('user', JSON.stringify(result));
            }
            return result;
        }
        catch (error) {
            console.error('Passkey registration failed:', error);
            throw error;
        }
    },

    createAccountWithPasskey: async (email) => {
        try {
            console.log('Starting account creation for:', email);

            const options = await authApi.createAccount(email);
            console.log('Received creation options:', options);

            if(options?.success === false || options?.errors){
                return options;
            }


            const credential = await navigator.credentials.create(options);
            console.log('Created credential:', credential);

            return await authApi.registerPasskey(email, credential, options.publicKey.challenge);
        }
        catch (error) {
            console.error('Account creation failed:', error);
            // Provide more detailed error information
            if (error instanceof DOMException) {
                throw new Error(`WebAuthn Error: ${error.name} - ${error.message}`);
            }
            throw error;
        }
    },

    createTempUser: async (email) => {
        try {
            const response = await fetch(`${constants.AUTH_API_URL}/register-temp-user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                                         email
                                     })
            });

            const rawData = await response.text();
            let encryptedData;
            try {
                encryptedData = JSON.parse(rawData);
            }
            catch (e) {
                encryptedData = rawData;
            }

            const decryptedData = await createCryptoInterceptor(() => encryptedData)();

            if (!response.ok) {
                return {
                    success: false,
                    message: decryptedData?.message || 'Registration failed'
                };
            }

            return {
                success: true,
                data: decryptedData
            };
        }
        catch (error) {
            return {
                success: false,
                message: error.message || 'Registration failed'
            };
        }
    },

    createAccountWithOtp: async (email, otp) => {
        try {
            const response = await fetch(`${constants.AUTH_API_URL}/register-with-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                                         email,
                                        otp
                                     })
            });

            const rawData = await response.text();
            let encryptedData;
            try {
                encryptedData = JSON.parse(rawData);
            }
            catch (e) {
                encryptedData = rawData;
            }

            const decryptedData = await createCryptoInterceptor(() => encryptedData)();

            if (!response.ok) {
                return {
                    success: false,
                    message: decryptedData?.message || 'Registration failed'
                };
            }

            if (decryptedData?.token) {
                localStorage.setItem('user', JSON.stringify(decryptedData));

                return {
                    success: true,
                    data: decryptedData
                };
            } else {
                throw new Error('Invalid server response');
            }


        }
        catch (error) {
            return {
                success: false,
                message: error.message || 'Registration failed'
            };
        }
    },

    // Combined Authentication Flow Methods
    authenticateWithPasskey: async (email, userId) => {
        try {
            const options = await authApi.getPasskeyAuthOptions(email);
            console.log('Options for authentication:', options);

            // Ensure the challenge is correctly set for WebAuthn
            const publicKeyOptions = {
                ...options.publicKey,
                challenge: base64ToBuffer(options.publicKey.challenge)
            };

            console.log('PublicKey options:', publicKeyOptions);

            const credential = await navigator.credentials.get({
                                                                   publicKey: publicKeyOptions
                                                               });

            console.log('Credential:', credential);

            // Decode the clientDataJSON to verify the origin and type
            const clientDataJSON = new TextDecoder().decode(credential.response.clientDataJSON);
            const clientData = JSON.parse(clientDataJSON);

            console.log('Client Data:', clientData);

            // Create options with the original base64 challenge for verification
            const verificationOptions = {
                ...options,
                publicKey: {
                    ...options.publicKey,
                    challenge: publicKeyOptions.challenge
                }
            };

            return await authApi.verifyPasskey(credential, verificationOptions, userId, email);
        }
        catch (error) {
            console.error('Authentication failed:', error);
            throw error;
        }
    },
    sendOtp: async (email) => {
        try {
            const response = await fetch(`${constants.AUTH_API_URL}/send-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({ email })
            });

            const rawData = await response.text();
            let encryptedData;
            try {
                encryptedData = JSON.parse(rawData);
            }
            catch (e) {
                encryptedData = rawData;
            }

            const result = await createCryptoInterceptor(() => encryptedData)();

            if (result?.otp) {
                return result;
            } else {
                throw new Error('Invalid server response');
            }
        }
        catch (error) {
            console.error('OTP send failed:', error);
            throw error;
        }
    },
    verifyOtp: async (email, otp) => {
        try {
            const response = await fetch(`${constants.AUTH_API_URL}/verify-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({ email, otp })
            });

            const rawData = await response.text();
            let encryptedData;
            try {
                encryptedData = JSON.parse(rawData);
            }
            catch (e) {
                encryptedData = rawData;
            }

            const result = await createCryptoInterceptor(() => encryptedData)();

            if (result?.token) {
                localStorage.setItem('user', JSON.stringify(result));
                return result;
            } else {
                throw new Error('Invalid server response');
            }
        }
        catch (error) {
            console.error('OTP verification failed:', error);
            throw error;
        }
    },

    // Google Callback
    googleAuth: async () => {
        try {
            // The callback URL on our React frontend
            const redirectUrl = `${window.location.origin}/auth/google/callback`;

            console.log('Google Redirect Url', redirectUrl);
            // Generate a state parameter with the current timestamp to prevent CSRF
            const state = Date.now().toString();

            // Store the state in localStorage for verification
            localStorage.setItem('google_auth_state', state);

            // Construct the backend URL for Google auth
            const googleAuthUrl = `${constants.AUTH_API_URL}/google/redirect?redirect_uri=${encodeURIComponent(redirectUrl)}&state=${state}`;

            console.log('Redirecting to Google auth:', googleAuthUrl);

            // Redirect the browser to the Google auth URL
            window.location.href = googleAuthUrl;

            // This is a redirect, so we don't return anything meaningful
            return new Promise(() => {});
        } catch (error) {
            console.error('Google auth error:', error);
            throw error;
        }
    }
};

export default authApi;
