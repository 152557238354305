// src/utils/constants.js

const constants = {
    // API URLs
    API_URL: process.env.REACT_APP_API_URL || 'http://localhost:3000/api/v1',
    AUTH_API_URL: process.env.REACT_APP_AUTH_API_URL || 'http://localhost:3000/api/auth',
    JIRA: process.env.REACT_APP_API_ENCRYPT_PASSPHRASE,

    // TOS AND PRIVACY POLICY
    TOS_URL: process.env.REACT_APP_TOS_URL,
    PRIVACY_URL: process.env.REACT_APP_PRIVACY_URL,

    // Other environment-specific variables
    NODE_ENV: process.env.NODE_ENV || 'development',
    APP_NAME: process.env.REACT_APP_NAME || 'My React App',

    // Feature flags
    ENABLE_FEATURE_X: process.env.REACT_APP_ENABLE_FEATURE_X === 'true',

    // Timeouts
    API_TIMEOUT: parseInt(process.env.REACT_APP_API_TIMEOUT || '5000', 10),

    // Add more constants as needed
    GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY || '',
    MAPBOX_ACCESS_TOKEN: process.env.REACT_APP_MAPBOX_API_KEY || '',
};

export default constants;
