const GeolocationManager = {
    STORAGE_KEY: 'userGeolocation',
    STALE_TIME: 30 * 60 * 1000, // 30 minutes

    getCurrentPosition(options = {}) {
        return new Promise((resolve, reject) => {
            if ("geolocation" in navigator) {
                const timeoutDuration = options.timeout || 10000; // 10 seconds default
                const geoOptions = {
                    enableHighAccuracy: options.enableHighAccuracy || false,
                    timeout: timeoutDuration,
                    maximumAge: options.maximumAge || 0
                };

                const timeoutId = setTimeout(() => {
                    reject(new Error("Geolocation request timed out"));
                }, timeoutDuration);

                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        clearTimeout(timeoutId);
                        const locationData = {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            accuracy: position.coords.accuracy,
                            timestamp: new Date().getTime()
                        };
                        localStorage.setItem(this.STORAGE_KEY, JSON.stringify(locationData));
                        resolve(locationData);
                    },
                    (error) => {
                        clearTimeout(timeoutId);
                        console.error("Error getting location:", error.message);
                        reject(error);
                    },
                    geoOptions
                );
            } else {
                reject(new Error("Geolocation is not supported by this browser."));
            }
        });
    },

    getStoredLocation() {
        const storedData = localStorage.getItem(this.STORAGE_KEY);
        return storedData ? JSON.parse(storedData) : null;
    },

    isLocationStale() {
        const storedLocation = this.getStoredLocation();
        if (!storedLocation) return true;
        const now = new Date().getTime();
        return (now - storedLocation.timestamp) > this.STALE_TIME;
    },

    async getLocation(options = {}) {
        const storedLocation = this.getStoredLocation();

        if (storedLocation && !this.isLocationStale() && !options.newLocation) {
            // Use stored location immediately
            setTimeout(() => this.updateLocationInBackground(options), 0);
            return storedLocation;
        }

        try {
            // Try to get a quick, low-accuracy location first
            const quickLocation = await this.getCurrentPosition({ enableHighAccuracy: false, timeout: 7500 });

            // Start getting a more accurate location in the background
            this.updateLocationInBackground({ ...options, enableHighAccuracy: true });

            return quickLocation;
        } catch (error) {
            console.error("Error getting quick location:", error);
            // Fall back to the stored location if available, otherwise rethrow
            if (storedLocation) return storedLocation;
            throw error;

            return {latitude: null, longitude: null};
        }
    },

    async updateLocationInBackground(options) {
        try {
            await this.getCurrentPosition(options);
        } catch (error) {
            console.error("Background location update failed:", error);
        }
    },

    async getLocationWithRetry(maxRetries = 3, initialDelay = 1000) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                return await this.getLocation();
            } catch (error) {
                retries++;
                if (retries >= maxRetries) throw error;
                await new Promise(resolve => setTimeout(resolve, initialDelay * Math.pow(2, retries - 1)));
            }
        }
    }
};

export default GeolocationManager;
